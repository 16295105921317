var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c("div", { staticClass: "col-lg-8 col-md-8 col-sm-12" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body form-card" }, [
            _c("div", { staticClass: "form-group row" }, [
              _vm._m(0),
              _c(
                "div",
                { staticClass: "col-10" },
                [
                  _c("el-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    class: _vm.errors.has("title") ? "border-danger" : "",
                    attrs: {
                      type: "text",
                      placeholder: "Tiêu đề",
                      "data-vv-name": "title",
                      "data-vv-as": "Tiêu đề",
                    },
                    model: {
                      value: _vm.form.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title",
                    },
                  }),
                  _vm.errors.has("title")
                    ? _c("span", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.errors.first("mail_title"))),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _vm._m(1),
              _c(
                "div",
                { staticClass: "col-10" },
                [
                  _c("my-editor", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    class: _vm.errors.has("content") ? "border-danger" : "",
                    attrs: {
                      apiKey:
                        "pgm9gcnllfimfcgo7joyxpm22j4ov87erg4u4fyy7gxo1g23",
                      "data-vv-name": "content",
                      "data-vv-as": "Nội dung mail",
                    },
                    model: {
                      value: _vm.form.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "content", $$v)
                      },
                      expression: "form.content",
                    },
                  }),
                  _vm.errors.has("content")
                    ? _c("span", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.errors.first("mail_content"))),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "col-lg-4 col-md-4 col-sm-12 sidebar-right" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body form-card" }, [
            _c(
              "div",
              { staticClass: "text-center" },
              [
                _c(
                  "el-upload",
                  {
                    staticClass: "upload-demo",
                    attrs: {
                      drag: "",
                      action: "",
                      "on-change": _vm.handleUploadChange,
                      "on-remove": _vm.handleRemoveUpload,
                      accept: ".xlsx,.xls",
                      "auto-upload": false,
                      multiple: false,
                      "file-list": _vm.list_files,
                    },
                  },
                  [
                    _c("i", { staticClass: "el-icon-upload" }),
                    _c("div", { staticClass: "el-upload__text" }, [
                      _vm._v("Chọn file"),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "el-upload__tip",
                        attrs: { slot: "tip" },
                        slot: "tip",
                      },
                      [_vm._v("Hỗ trợ file xls, xlsx")]
                    ),
                  ]
                ),
                _vm._m(2),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body form-card" }, [
            _vm.variables.length
              ? _c("div", [
                  _c("div", [
                    _vm._v("Số lượng email: "),
                    _c("span", { staticClass: "badge badge-success" }, [
                      _vm._v(_vm._s(_vm.users.length)),
                    ]),
                  ]),
                  _c("div", [_vm._v("Danh sách biến:")]),
                  _vm.variables.length
                    ? _c("div", { staticClass: "col-12" }, [
                        _c(
                          "ul",
                          { staticStyle: { "padding-left": "0" } },
                          _vm._l(_vm.variables, function (item) {
                            return _c("li", [
                              _c("code", [_vm._v(_vm._s(item))]),
                            ])
                          }),
                          0
                        ),
                      ])
                    : _vm._e(),
                ])
              : _c("div", { staticClass: "text-center" }, [
                  _c("div", [_vm._v("Danh sách biến: N/A")]),
                ]),
          ]),
        ]),
      ]),
      _c("loading", {
        attrs: { active: _vm.is_loading },
        on: {
          "update:active": function ($event) {
            _vm.is_loading = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-2" }, [
      _c("label", { staticClass: "required" }, [_vm._v("Tiêu đề")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-2" }, [
      _c("label", { staticClass: "required" }, [_vm._v("Nội dung")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c(
        "a",
        {
          attrs: {
            href: "https://irace.vn/races/files/import_mail_merge.xlsx",
            target: "_blank",
          },
        },
        [_vm._v("Tải file mẫu")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }