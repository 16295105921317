<template lang="html">
  <div class="row">

    <div class="col-lg-8 col-md-8 col-sm-12">
      <div class="card">
        <div class="card-body form-card">

          <div class="form-group row">
            <div class="col-2">
              <label class="required">Tiêu đề</label>
            </div>
            <div class="col-10">
              <el-input
                type="text"
                placeholder="Tiêu đề"
                v-model="form.title"
                :class="errors.has('title') ? 'border-danger' : ''"
                v-validate="'required'"
                data-vv-name="title"
                data-vv-as="Tiêu đề"
              ></el-input>
              <span class="text-danger" v-if="errors.has('title')">{{ errors.first('mail_title') }}</span>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-2">
              <label class="required">Nội dung</label>
            </div>
            <div class="col-10">
              <my-editor
                v-model="form.content"
                apiKey="pgm9gcnllfimfcgo7joyxpm22j4ov87erg4u4fyy7gxo1g23"
                :class="errors.has('content')?'border-danger':''"
                v-validate="'required'"
                data-vv-name="content"
                data-vv-as="Nội dung mail">
              </my-editor>
              <span class="text-danger" v-if="errors.has('content')">{{ errors.first('mail_content') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-4 col-md-4 col-sm-12 sidebar-right">
      <div class="card">
        <div class="card-body form-card">
          <div class="text-center">
            <el-upload
              class="upload-demo"
              drag
              action=""
              :on-change="handleUploadChange"
              :on-remove="handleRemoveUpload"
              accept=".xlsx,.xls"
              :auto-upload="false"
              :multiple="false"
              :file-list="list_files">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">Chọn file</div>
              <div class="el-upload__tip" slot="tip">Hỗ trợ file xls, xlsx</div>
            </el-upload>
            <div>
              <a href="https://irace.vn/races/files/import_mail_merge.xlsx" target="_blank">Tải file mẫu</a>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body form-card">

          <div v-if="variables.length">
            <div>Số lượng email: <span class="badge badge-success">{{ users.length }}</span></div>
            <div>Danh sách biến:</div>
            <div v-if="variables.length" class="col-12">
              <ul style="padding-left: 0;">
                <li v-for="item in variables"><code>{{ item }}</code></li>
              </ul>
            </div>
          </div>

          <div class="text-center" v-else>
            <div>Danh sách biến: N/A</div>
          </div>

        </div>
      </div>

    </div>

    <loading :active.sync="is_loading"></loading>

  </div>
</template>

<script>

import { Input, Button, Message, Select, Option, Tag, MessageBox, Upload } from 'element-ui';
import MyEditor from 'src/components/UIComponents/Editor'
import MySelect from 'src/components/UIComponents/Select'
import { mapState } from 'vuex'
import { mapActions } from 'vuex'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  components: {
    ElInput: Input,
    ElButton: Button,
    ElSelect: Select,
    ElOption: Option,
    ElTag: Tag,
    ElUpload: Upload,
    MyEditor,
    MySelect,
    Message,
    Loading
  },

  data() {
    return {
      form: {},
      variables: [],
      users: [],
      is_loading: false,
      list_files: [],
      page: 1
    }
  },

  mounted() {
    this.$store.dispatch('setPageTitle', 'Mail Merge');
    this.$store.dispatch('setCurrentActions', [{
      label: 'Gửi email',
      type: 'primary',
      icon: '',
      callback: this.send
    }])
  },

  methods: {
    handleUploadChange(file, list_files) {
      this.list_files = list_files.slice(-1);
      this.uploadFile();
    },

    handleRemoveUpload(file, list_files) {
      this.list_files = [];
    },

    async uploadFile() {
      if (this.list_files.length == 0) {
        return Message({type: 'error', message: 'Vui lòng chọn file'});
      }

      this.is_loading = true;
      const formData = new FormData();
      formData.append('file_import', this.list_files[0].raw);
      await this.$store.dispatch('importMailMerge', formData).then(res => {
        let data = res.data;
        this.variables = data.variables.slice();
        this.users = data.users.map(x => Object.assign({}, x));
      });
      this.is_loading = false;
    },

    async send() {
      let self = this;
      this.$validator.validateAll().then(result => {
        if (!result) return false;

        if (!self.users.length) {
          return Message({message: 'Chưa nhập danh sách users', type: 'error'});
        }

        var data = {
          title: self.form.title,
          content: self.form.content,
          users: self.users,
          variables: self.variables
        };

        MessageBox.confirm(`Gửi email cho ${data.users.length} người?`, 'Warning', {
          confirmButtonText: 'Đồng ý',
          cancelButtonText: 'Hủy bỏ',
          type: 'warning',
          center: true
        }).then(_ => {

          self.is_loading = true;

          self.$store.dispatch('sendMailMerge', data).then(res => {
            Message({message: 'Hệ thống đang xử lý', type: 'success'});
            self.is_loading = false;
          }, error => {
            self.is_loading = false;
            Message({message: error, type: 'error'});
          });

        });

      });

    }
  },

  destroyed() {
    this.$store.dispatch('setCurrentActions', []);
  }
}
</script>
